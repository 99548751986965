import search from './search/index.ts';
import colorSearch from './colorSearch.ts';
import fitmentSearch from './fitmentSearch/index.ts';
import { combineAPI } from 'Addons/combine.ts';
import {
  colorSearchEnabled,
  fitmentSearchEnabled,
  searchEnabled,
} from '!!extract-features!Stores/tototire-1723/config.js';

import type { Addon } from 'Addons/combine.ts';
import type { ReduxStoreType } from 'Core/store.ts';

const addons: Addon[] = [];
if (searchEnabled) {
  addons.push(search);
}
if (colorSearchEnabled) {
  addons.push(colorSearch);
}
if (fitmentSearchEnabled) {
  addons.push(fitmentSearch);
}

export const names = addons.map((a) => a.name);

const { initialize } = combineAPI(...addons);

const initializeAddons = (store: ReduxStoreType): void => initialize?.(store, {});

export { initializeAddons };
