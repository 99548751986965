
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('li', { 'className': 'collection__grid-item' }, _createElement('div', { 'className': 'grid-item group relative h-full' }, _createElement('div', { 'className': 'product-card relative flex flex-col flex-wrap items-stretch space-y-3 h-full' }, _createElement('a', {
                    'href': this.url,
                    'className': 'product-card__media relative z-20 flex justify-center aspect-h-3',
                    'tabIndex': '-1'
                }, _createElement('img', {
                    'className': 'product-card__featured-image object-contain',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), _createElement('div', { 'className': 'product-card__labels-container inset-0 absolute' }, _createElement('div', { 'className': 'product-card__labels absolute bottom-1 right-1 space-x-2 text-2xs uppercase font-navigation font-weight-navigation-bolder tracking-tight' }, this.on_sale ? _createElement('span', {
                    'className': 'label label--accent rounded-sm py-0.5 px-2 bg-primary-accent text-primary-accent-text',
                    'key': '727'
                }, '\n              Sale\n            ') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'label label--basic rounded-sm py-0.5 px-2 bg-primary-background text-primary-accent',
                    'key': '900'
                }, '\n              Sold out\n            ') : null))), _createElement('div', { 'className': 'product-card__content leading-tight' }, _createElement('div', { 'className': 'product-card__title group-hover:text-primary-accent' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-card__vendor relative z-20 text-sm text-primary-text-80' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('div', { 'className': 'product-card__inventory-container' }, this.many_stock ? _createElement('span', {
                    'className': 'product-card__inventory in-stock',
                    'key': '1617'
                }, ' IN STOCK - 20+ ') : null, this.inventory_quantity && this.inventory_quantity > 5 ? _createElement('span', {
                    'className': 'product-card__inventory in-stock',
                    'key': '1722'
                }, '\n            IN STOCK - ', this.inventory_quantity, '\n          ') : null, this.inventory_quantity && this.inventory_quantity <= 5 ? _createElement('span', {
                    'className': 'product-card__inventory low-stock',
                    'key': '1921'
                }, '\n            Only ', this.inventory_quantity, ' units left\n          ') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'product-card__inventory out-of-stock',
                    'key': '2127'
                }, ' Out of Stock ') : null), _createElement('div', { 'className': 'product-card__price pt-0.5' }, _createElement('div', { 'className': 'price__sale' }, _createElement('span', { 'className': 'price-item price-item--sale' }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price))), this.on_sale ? _createElement('span', {
                    'className': 'price-item price-item--compare',
                    'key': '2485'
                }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))) : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []