import shopifyGenericDefaults from '../shopify-generic/config.js';

// On these two collections facets are not tired to fitment search, there is the "Vehicle Make" facet
const isPageWithFitmentSearch =
  window.location.pathname !== '/collections/winter-tire-package' &&
  window.location.pathname !== '/collections/top-pick-collection';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').redirectUrl =
  '/collections/wheels-collection';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'VehicleWidgetDialog').redirectUrl =
  '/collections/wheels-collection';

// disable fitment search on these collections by hiding this widget
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').visibleIf = () =>
  isPageWithFitmentSearch;

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  facets: {
    ...shopifyGenericDefaults.facets,
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1' }],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => w.name !== 'VerifyFitment'),
    {
      name: 'FilterSearchBox',
    },
    {
      name: 'HomeVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 680,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-wheels',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: 'collections/wheels-collection',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-tire',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tire-selection',
      selectFields: tiresFields,
    },
    {
      name: 'VerifyFitment',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
  ],
};
